import Accordion from "react-bootstrap/Accordion";
import styled from "styled-components";

const StyledAccordion = styled(Accordion)`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
`;

const AccordionContainer = ({ children }) => {
  return <StyledAccordion defaultActiveKey="0">{children}</StyledAccordion>;
};

export default AccordionContainer;
