import React from "react"
import * as Styled from "./styles"
import Logo from "../../../components/Logo"
import FadeTransition from "../../../components/FadeInTransition"
import { SignupProvider } from "../../../features/signup/SignupProvider"
import SignupForm from "../../../components/Signup/SignupForm"
import { LOGIN_PAGE_PATH } from "../../../routes/navigation_routes"
import { useNavigate } from "react-router-dom"
import { useSignupMutation } from "../../../features/auth/api/mutations"
import { errorMsg } from "../../../helpers/errorMsg"

const SignupPage = () => {
    const [signupMutation, { isLoading }] = useSignupMutation()
    const navigate = useNavigate()

    const onSubmit = (data) => {
        signupMutation({ user: data })
            .unwrap()
            .then(() => {
                return navigate(LOGIN_PAGE_PATH)
            }).catch(err => {
                errorMsg(err.data.message)
            })
    }

    return (
        <FadeTransition>
            <Styled.LoginPane>
                <SignupProvider>
                    <Styled.FormContainerPane>
                        <Styled.LogoContainerPane>
                            <Styled.AuthTitle>Inscription</Styled.AuthTitle>
                            <Logo />
                        </Styled.LogoContainerPane>
                        <SignupForm isLoading={isLoading} onSignup={onSubmit} />
                        <Styled.AltLink to={LOGIN_PAGE_PATH}>Vous avez deja un compte? Connectez-vous.</Styled.AltLink>
                    </Styled.FormContainerPane>
                </SignupProvider>
            </Styled.LoginPane>
        </FadeTransition>
    )
}

export default SignupPage