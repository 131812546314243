import { joursDeLaSemaineEnFr } from "../constants"

export const getNextWeekDays = (date = new Date()) => {
    let nextWeekFirstDayDateNumber = (new Date(date).getDate() - new Date(date).getDay() + 1) + 7
    const nextWeekFirstDayDate = new Date(new Date(date.setDate(nextWeekFirstDayDateNumber)).toISOString().slice(0, 10))
    let week = []

    for (let i = 1; i <= 7; i++) {
        let first = nextWeekFirstDayDate.getDate() - nextWeekFirstDayDate.getDay() + i
        let day = new Date(nextWeekFirstDayDate.setDate(first)).toISOString().slice(0, 10)
        // console.log({ first, day });
        week.push({ date: day, day: joursDeLaSemaineEnFr[i - 1] })
    }

    return week
}