import React from 'react'
import ButtonLink from '../../../components/ButtonLink'
import { rootStyles } from '../../../style/shared'

const GoogleDocsBtn = ({ label, fontSize, paddingHorizontal, paddingVertical }) => {
    return (
        <ButtonLink
            type="Link"
            // to={"https://docs.google.com/forms/d/1VGgE9YQ4peupOWdU5UY1ScfJgIvtJbQBxqMLa3hk5nY/viewform?edit_requested=true"}
            to={"https://pro.kiliya.co/"}
            letterSpacing={3}
            fontSize={fontSize}
            fontWeight={500}
            textTransform="uppercase"
            paddingVertical={paddingVertical}
            paddingHorizontal={paddingHorizontal}
            borderWidth={2}
            borderStyle="solid"
            borderColor={rootStyles.colors.tercary}
            borderRadius={rootStyles.borderRadius.xl}
            backgroundColor={rootStyles.colors.tercary}
            color={rootStyles.colors.primary}
            hoverBackgroundColor={rootStyles.colors.primary}
            hoverColor={rootStyles.colors.tercary}
        >
            {label}
        </ButtonLink>
    )
}

export default GoogleDocsBtn