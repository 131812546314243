import { kiliyaApi } from "../../../../redux/rtkquery";
import { SIGNIN_ENDPOINT, SIGNOUT_ENDPOINT, SIGNUP_ENDPOINT } from "../../../../routes/api/endpoints";

const authApi = kiliyaApi.injectEndpoints({
    endpoints: (builder) => ({
        signin: builder.mutation({
            query: (user) => ({
                method: "POST",
                url: SIGNIN_ENDPOINT,
                body: user
            }),
            invalidatesTags: ["User"]
        }),
        signup: builder.mutation({
            query: (user) => ({
                method: "POST",
                url: SIGNUP_ENDPOINT,
                body: user
            }),
            providesTags: ["User"]
        }),
        logout: builder.mutation({
            query: () => ({
                method: "DELETE",
                url: SIGNOUT_ENDPOINT
            }),
            providesTags: ["User"]
        }),
    })
})
export const { useSigninMutation, useSignupMutation, useLogoutMutation } = authApi