import React from "react"

/**
 *
 * @param {string} query - media query to check for
 * @returns {boolean} whether it matches or not
 */
const useMediaQuery = (query) => {
    if (typeof query !== "string") {
        throw Error("Given query isn't a string.")
    }
    const [checksout, setChecksout] = React.useState(window.matchMedia(""))

    React.useEffect(() => {
        const result = window.matchMedia(query)
        result.onchange = (event) => {
            setChecksout(event.currentTarget.matches)
        }

        setChecksout(result.matches)
    }, [query])

    return checksout
}

export default useMediaQuery
