import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { rootStyles } from "../../../style/shared"
import { BsArrowLeft, BsArrowRight } from "react-icons/bs"
import FadeInSpring from "../../../components/FadeInSpring"
import Flex from "../../../components/Flex"
import Div from "../../../components/Div"
import { addDateToReservation } from "../../../redux/features/reservationSlice"
import Button from "../../../components/Button"

const DayPane = styled.p`
    font-size: ${rootStyles.fontSize.md - 5}px;
    font-weight: 600;
    text-align: center;
`
const DatePane = styled.p`
    text-align: center;
`
const CalendarPane = styled.div`
    background-color: ${rootStyles.colors.primary};
    padding: ${rootStyles.padding.lg}px ${rootStyles.padding.lg}px;
    border-radius: 8px;
    overflow: auto;
    width: 100%;

    @media (max-width: ${rootStyles.breakpoints.calandarLg}px) {
        padding: ${rootStyles.padding.lg}px ${rootStyles.padding.lg + 50}px;
        display: none;
    }
`
const TImeContaintPane = styled.span`
    background-color: #f7f7f7;
    display: block;
    padding: 8px 10px;
    text-align: center;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    max-width: 70px;
    margin: 10px auto;

    &:hover{
        background-color: ${rootStyles.colors.primary};
        color: ${rootStyles.colors.white};
    }
`

const DesktopCalendar = ({ cardRef, onClickPrev, onClickNext, reservation }) => {
    const { currentSalon } = useSelector(state => state.salons)
    const dispatch = useDispatch()
    console.log("desktop calendar");
    return <FadeInSpring ref={cardRef}>
        <CalendarPane opacity={true}>
            {
                <Flex justify={"space-between"} align={"flex-start"} gap={30}>
                    {/* previous week */}
                    <Button
                        onClick={onClickPrev}
                        // backgroundColor="red"
                        borderWidth={2}
                        borderColor={rootStyles.colors.secondary}
                        paddingHorizontal={20}
                        borderStyle={"solid"}
                    // hoverBackgroundColor={rootStyles.colors.tercary}
                    >
                        <BsArrowLeft size={20} />
                    </Button>
                    <Flex flex={2} gap={30} justify={"space-evenly"} minwidth={500}>
                        {
                            reservation.employeFormaDays?.map(day => {
                                // console.log({ day });
                                return <Flex key={day.weekDay} direction='column' align={"space-between"} flex={1} gap={10}>
                                    <Div>
                                        <Div>
                                            <DayPane>{day.weekDay}</DayPane>
                                            {/* <span>date</span> */}
                                        </Div>
                                        <Div>
                                            <DatePane>
                                                {new Date(day.date).toLocaleDateString("fr-Fr", {
                                                    month: "short", // month
                                                    day: "numeric",
                                                })}
                                            </DatePane>
                                        </Div>
                                    </Div>
                                    <Div>
                                        {
                                            day.available_hours.map(hour => {
                                                return <TImeContaintPane onClick={() => {
                                                    return dispatch(addDateToReservation({
                                                        date: {
                                                            // fullDate: new Date(day.date).toLocaleString("fr-Fr", {
                                                            //     day: "2-digit",
                                                            //     month: "2-digit",
                                                            //     year: "numeric"
                                                            // }),
                                                            fullDate: day.date,
                                                            weekDay: day.weekDay,
                                                            hour
                                                        },
                                                        reservationLineId: reservation.id,
                                                        idSalon: currentSalon.id
                                                    }))
                                                }}>{hour}</TImeContaintPane>
                                            })
                                        }
                                    </Div>
                                </Flex>
                            })
                        }
                    </Flex>
                    {/* next week */}
                    <Button
                        onClick={onClickNext}
                        // backgroundColor="red"
                        borderWidth={2}
                        borderColor={rootStyles.colors.secondary}
                        paddingHorizontal={20}
                        borderStyle={"solid"}
                    // hoverBackgroundColor={rootStyles.colors.tercary}
                    >
                        <BsArrowRight size={20} />
                    </Button>
                </Flex>
            }
        </CalendarPane>
    </FadeInSpring>
}

export default DesktopCalendar