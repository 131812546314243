import { FormProvider, useForm } from "react-hook-form"
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup"

const signupSchema = yup.object().shape({
    first_name: yup.string()
        .required("Prénom obligatoire"),
    last_name: yup.string()
        .max(20, "Nom trop long"),
    phone: yup.string()
        .required("Téléphone portable obligatoire"),
    email: yup.string()
        .required("Email obligatoire")
        .email("Veuillez fournir un mail valide"),
    password: yup.string()
        .required("Mot de passe obligatoire")
        .min(5, "Le mot de passe doit avoir au moins 5 caractères"),
    city: yup.string()
        .max("50", "Valeur trop grande"),
    address: yup.string()
        .max("50", "Valeur trop grande"),
})

export const SignupProvider = ({ children }) => {

    const matches = useForm({
        values: initialValues,
        resulver: yupResolver(signupSchema)
    })

    return <FormProvider {...matches}>{children}</FormProvider>
}
const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    city: "",
    address: ""
}