import React from 'react'
import { useFormContext } from 'react-hook-form'
import 'react-phone-number-input/style.css'

import Flex from '../Flex'
import Input from '../Input'
import { rootStyles } from '../../style/shared'
import Div from '../Div'
import Button from '../Button'
import CustomPhoneNumber from '../Input/CustomPhoneNumber'

const SignupForm = ({ isLoading, onSignup }) => {
    const { handleSubmit, register, control, formState: { errors } } = useFormContext()

    const onSubmit = (values) => {
        onSignup(values)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex align={"center"} gap={10} flex={1} wrap={"wrap"}>
                <Input
                    label={"Prénom"}
                    placeholder={"Prénom"}
                    type={"text"}
                    error={errors.first_name?.message}
                    register={{ ...register("first_name", { required: "Prénom obligatoire" }) }}
                />
                <Input
                    label={"Nom"}
                    placeholder={"Nom"}
                    type={"text"}
                    error={errors.last_name?.message}
                    register={{ ...register("last_name", { required: "Nom obligatoire" }) }}
                />
                <CustomPhoneNumber name="phone" label="Téléphone portable" control={control} />
                <Input
                    optional={true}
                    label={"Ville"}
                    placeholder={"Ville"}
                    type={"text"}
                    register={{ ...register("city") }}
                />
                <Input
                    label={"Adresse"}
                    placeholder={"Adresse"}
                    type={"text"}
                    register={{ ...register("address") }}
                />
                <Input
                    label={"Email"}
                    placeholder={"Email"}
                    type={"email"}
                    error={errors.email?.message}
                    register={{ ...register("email", { required: "Email obligatoire" }) }}
                />
                <Input
                    isPassword
                    label={"Mot de passe"}
                    placeholder={"Mot de passe"}
                    type={"password"}
                    error={errors.password?.message}
                    register={{ ...register("password", { required: "Mot de passe obligatoire" }) }}
                />
            </Flex>
            <Div mt={20} mb={20}>
                <Button
                    disabled={isLoading}
                    width={100}
                    letterSpacing={3}
                    fontSize={rootStyles.fontSize.sm}
                    fontWeight={600}
                    textTransform="uppercase"
                    paddingVertical={rootStyles.padding.md}
                    borderWidth={2}
                    borderStyle="solid"
                    borderColor={rootStyles.colors.action}
                    borderRadius={rootStyles.borderRadius.xl}
                    backgroundColor={rootStyles.colors.action}
                    color={rootStyles.colors.primary}
                // hoverBackgroundColor={rootStyles.colors.primary}
                // hoverColor={rootStyles.colors.action}
                // width={100}
                >
                    {
                        isLoading ? "Inscription en cours..." : "Créer un compte"
                    }
                </Button>
            </Div>
        </form >
    )
}

export default SignupForm