import React, { memo } from 'react'
import Div from '../../../components/Div'
import Flex from '../../../components/Flex'
import Button from '../../../components/Button'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { HonestWeekPicker } from '../../../components/WeekPicker/WeekPicker'
import AccordionContainer from '../../../components/AccordionContainer'
import AccordionItem from '../../../components/AccordionItem'
import styled from 'styled-components'
import { rootStyles } from '../../../style/shared'
import { useDispatch, useSelector } from 'react-redux'
import { addDateToReservation } from '../../../redux/features/reservationSlice'

const MobileCalendarPane = styled.div`
    display: none;
    @media screen and (max-width: ${rootStyles.breakpoints.calandarLg}px) {
        display: block;
    }
`
const TImeContaintPane = styled.span`
    background-color: #f7f7f7;
    display: block;
    padding: 8px 10px;
    text-align: center;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    max-width: 70px;
    margin: 10px auto;

    &:hover{
        background-color: ${rootStyles.colors.primary};
        color: ${rootStyles.colors.white};
    }
`

const MobileCalendar = ({ reservation, onClickPrev, onClickNext, onSelectWeek }) => {
    const { currentSalon } = useSelector(state => state.salons)
    const dispatch = useDispatch()

    return (
        <MobileCalendarPane>
            <Div mb={15} mt={29}>
                <h4>Choisissez la date</h4>
                <Flex justify={"space-between"} align={"center"}>
                    <Button
                        onClick={onClickPrev}
                        // backgroundColor="red"
                        borderWidth={2}
                        borderColor={rootStyles.colors.secondary}
                        paddingHorizontal={15}
                        paddingVertical={5}
                        borderStyle={"solid"}
                    // hoverBackgroundColor={rootStyles.colors.tercary}
                    >
                        <BsArrowLeft size={15} />
                    </Button>
                    <HonestWeekPicker
                        firstDay={reservation.employeFormaDays[0]?.date}
                        lastDay={reservation.employeFormaDays[reservation.employeFormaDays.length - 1]?.date}
                        onChange={(week) => onSelectWeek(week)} />
                    <Button
                        onClick={onClickNext}
                        // backgroundColor="red"
                        borderWidth={2}
                        borderColor={rootStyles.colors.secondary}
                        paddingHorizontal={15}
                        paddingVertical={5}
                        borderStyle={"solid"}
                    // hoverBackgroundColor={rootStyles.colors.tercary}
                    >
                        <BsArrowRight size={15} />
                    </Button>
                </Flex>
            </Div>
            {
                reservation.employeFormaDays?.map((day, index) => {
                    return <Div mb={10} key={index}>
                        <AccordionContainer>
                            <AccordionItem
                                accordionTitle={day.weekDay + " - " + new Date(day.date).toLocaleDateString("fr-Fr", {
                                    month: "short", // month
                                    day: "numeric",
                                })}
                            >
                                <Flex gap={10} wrap={"wrap"} justify={"flex-start"}>
                                    {
                                        day.available_hours.length ? day.available_hours.map((hour, index) => {
                                            return <TImeContaintPane key={index} onClick={() => {
                                                return dispatch(addDateToReservation({
                                                    date: {
                                                        // fullDate: new Date(day.date).toLocaleString("fr-Fr", {
                                                        //     day: "2-digit",
                                                        //     month: "2-digit",
                                                        //     year: "numeric"
                                                        // }),
                                                        fullDate: day.date,
                                                        weekDay: day.weekDay,
                                                        hour
                                                    },
                                                    reservationLineId: reservation.id,
                                                    idSalon: currentSalon.id
                                                }))
                                            }}>{hour}</TImeContaintPane>
                                        }) : <p>Pas disponible</p>
                                    }
                                </Flex>
                            </AccordionItem>

                        </AccordionContainer>
                    </Div>
                }) 
            }
        </MobileCalendarPane>
    )
}

export default memo(MobileCalendar)