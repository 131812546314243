import { joursDeLaSemaineEnFr } from "../constants"

/**
 * Retourne les date de la semaine en cours en fonction de la date donnee
 * @param {date} date 
 * @returns string[]
 */
export const getCurrentWeekDays = (date = new Date()) => {
    let today = new Date(date)
    let week = []

    for (let i = 1; i <= 7; i++) {
        let first = today.getDate() - today.getDay() + i
        let day = new Date(today.setDate(first)).toISOString().slice(0, 10)
        week.push({ date: day, day: joursDeLaSemaineEnFr[i - 1] })
    }

    return week
}

/** 
 * Exemple:
 * 
 * [
 * {date: '2024-01-22', day: 'Lundi'},
 * {date: '2024-01-23', day: 'Mardi'},
 * {date: '2024-01-24', day: 'Mercredi'},
 * {date: '2024-01-25', day: 'Jeudi'},
 * {date: '2024-01-26', day: 'Vendredi'},
 * {date: '2024-01-27', day: 'Samedi'},
 * {date: '2024-01-28', day: 'Dimanche'}
 * ]
 */