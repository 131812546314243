import React, { memo, useRef } from 'react'
import styled from 'styled-components'
import { rootStyles } from '../../../style/shared'
import Flex from '../../../components/Flex'
import SelectOptions from '../../../components/SelectOptions';
import Dot from '../../../components/Dot';
import { useDispatch, useSelector } from 'react-redux';
import { addEmployeToReservation, deleteReservation, setCalendarNextWeek, setCalendarPreviousWeek, setCalendarSelectedWeek } from '../../../redux/features/reservationSlice';
import Div from '../../../components/Div';
import FadeInSpring from '../../../components/FadeInSpring';

import DesktopCalendar from './DesktopCalendar';
import MobileCalendar from './MobileCalendar';
import { convertTimeToHour } from '../../../utils/convertTimeToHour';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { AiFillWarning } from 'react-icons/ai';

const CardPane = styled.div`
    padding: ${rootStyles.padding.lg}px;
    background-color: ${rootStyles.colors.primary};
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    margin-bottom: 15px;
    border-radius: 8px;
    // border: 2px solid rgba(50, 70, 90, 0.1)
`
const TitlePane = styled.span`
    font-size: ${rootStyles.fontSize.mg}px
`
const DurationPane = styled.span`
    color: ${rootStyles.colors.avisColor}
`
const PricePane = styled.span`
    font-size: ${rootStyles.fontSize.sm}px;
    font-weight: 600 !important;
`

export const DeleteBtnPane = styled.button`
    all: unset;
    border-bottom: 1px solid ${rootStyles.colors.action};
    color: ${rootStyles.colors.action};
    transition: all 0.2s ease;
    
    &:active{
        transform: scale(.9)
    }
`
const TImeContaintPane = styled.span`
    background-color: #f7f7f7;
    display: block;
    padding: 8px 10px;
    text-align: center;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    max-width: 70px;
    // margin: 10px auto;

    &:hover{
        background-color: ${rootStyles.colors.primary};
        color: ${rootStyles.colors.white};
    }
`


const ReservationCardItem = ({ reservation }) => {
    const { currentSalon } = useSelector(state => state.salons)
    const dispatch = useDispatch()
    const cardRef = useRef()
    const isUnderCalendarLg = useMediaQuery(`(max-width: ${rootStyles.breakpoints.calandarLg}px)`)


    const currentReservationEmployesOptions = reservation?.reservation?.employee?.map(employee => {
        return { value: employee.id, label: employee.first_name + ' ' + employee?.last_name ?? "" }
    }) || []


    const handleOnChangeEmploye = (value) => {
        const selectedEmployee = reservation?.reservation?.employee.filter(employee => employee.id === value.value)[0]
        // ! pour animer le rendu des cards
        cardRef?.current?.handleClick()

        return dispatch(addEmployeToReservation({
            employe: value,
            reservationLineId: reservation.id,
            selectedEmployee,
            idSalon: currentSalon.id
        }))
    }

    const getPreviousWeekDaysFunc = (date) => {
        dispatch(setCalendarPreviousWeek({
            reservationId: reservation.id,
            idSalon: currentSalon.id,
            currentWeekFirstDay: date
        }))
    }
    const getNextWeekDaysFunc = (date) => {
        dispatch(setCalendarNextWeek({
            reservationId: reservation.id,
            idSalon: currentSalon.id,
            currentWeekFirstDay: date
        }))
    }
    const onSelectWeekFunc = (week) => {
        console.log("setCalendarSelectedWeek", week);
        dispatch(setCalendarSelectedWeek(
            {
                reservationId: reservation.id,
                idSalon: currentSalon.id,
                firstDay: week.firstDay
            }
        ))
    }


    return (
        <FadeInSpring style={{ padding: 5 }}>
            <CardPane key={reservation.id}>
                <Flex justify={"space-between"} align={"center"} wrap={"wrap"} gap={20}>
                    <Flex direction='column' minwidth={200}>
                        <TitlePane>{reservation.reservation.name}</TitlePane>
                        <Flex align={"center"} wrap={"wrap"}>
                            <Flex align={"center"}>
                                <DurationPane>{convertTimeToHour(reservation.reservation.duration)}min</DurationPane>
                                <Dot />
                                <PricePane>{reservation.reservation.price} FCFA</PricePane>
                            </Flex>
                            {
                                reservation.date && <>
                                    <Dot />
                                    <Flex gap={10} wrap={"wrap"} align={"center"} minwidth={200}>
                                        <Div>{reservation.date.weekDay} {new Date(reservation.date.fullDate).toLocaleDateString("fr-Fr", {
                                            month: "short", // month
                                            day: "numeric",
                                            year: "numeric"
                                        })}</Div>
                                        <Div>à</Div>
                                        <TImeContaintPane >
                                            {reservation.date.hour}
                                        </TImeContaintPane>
                                        <Div>avec</Div>
                                        <PricePane>{reservation.employe.label}</PricePane>
                                    </Flex>
                                </>
                            }
                        </Flex>
                    </Flex>
                    <Flex align={"center"} gap={20} wrap={"wrap"}>
                        {
                            !reservation?.date && <Div>
                                <SelectOptions
                                    defaultValue={reservation?.employe}
                                    width={250}
                                    onChange={(value) => handleOnChangeEmploye(value)}
                                    placeholder={"Avec qui ?"}
                                    options={currentReservationEmployesOptions}
                                    padding={0}
                                    borderWidth={1}
                                />
                                {
                                    !reservation.employe && <span style={{ position: "absolute", bottom: -20, fontSize: "13px", color: "red" }}>Veuillez sélectionner un employé</span>
                                }
                                {
                                    (reservation.employe && !reservation.date) && <span style={{ position: "absolute", bottom: -20, fontSize: "13px", color: "red" }}>Veuillez sélectionner une date</span>
                                }

                            </Div>
                            // reservation?.employe
                        }
                        <DeleteBtnPane
                            onClick={() => dispatch(deleteReservation({ reservationLineId: reservation.id, idSalon: currentSalon.id }))}
                        >
                            Supprimer
                        </DeleteBtnPane>
                    </Flex>
                </Flex>
            </CardPane>

            {
                (reservation?.employeFormaDays?.length) ? (!reservation.date && reservation?.employe && !isUnderCalendarLg) ?
                    <DesktopCalendar
                        reservation={reservation}
                        cardRef={cardRef}
                        onClickPrev={() => getPreviousWeekDaysFunc(reservation.employeFormaDays[0].date)}
                        onClickNext={() => getNextWeekDaysFunc(reservation.employeFormaDays[0].date)}
                    /> : reservation?.date?.fullDate ? null :
                    <MobileCalendar
                            onSelectWeek={(week) => onSelectWeekFunc(week)}
                            // onSelectWeek={() => { }}
                        reservation={reservation}
                        // cardRef={cardRef}
                        onClickPrev={() => getPreviousWeekDaysFunc(reservation.employeFormaDays[0].date)}
                        onClickNext={() => getNextWeekDaysFunc(reservation.employeFormaDays[0].date)}
                    /> : !reservation?.employe?.label ? null :
                    <div style={{
                        textAlign: "center",
                        color: "red",
                            // boxShadow: "rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px",
                    }}>
                        <AiFillWarning size={25} />
                        <p>{reservation?.employe?.label} n'a pas encore de jours de travail</p>
                        {/* <Div mt={10}>
                            <Flex justify={"center"}>
                                <Button
                                    onClick={() => setShowCalendar(false)}
                                    borderColor={rootStyles.colors.salonDetailsColor}
                                    borderStyle={"solid"}
                                    borderWidth={1}
                                    paddingHorizontal={20}
                                >
                                    Fermer
                                </Button>
                            </Flex>
                        </Div> */}
                        {/* <p style={{ textAlign: "right" }}>Fermer</p> */}
                    </div>
            }
            {/*
            {
                (!reservation.date && reservation?.employe && !isUnderCalendarLg) && <DesktopCalendar
                    reservation={reservation}
                    cardRef={cardRef}
                    onClickPrev={() => getPreviousWeekDaysFunc(reservation.employeFormaDays[0].date)}
                    onClickNext={() => getNextWeekDaysFunc(reservation.employeFormaDays[0].date)}
                />
            }
            {
                (!reservation.date && reservation?.employe && isUnderCalendarLg) && <MobileCalendar
                    onSelectWeek={(week) => onSelectWeekFunc(week)}
                    reservation={reservation}
                    cardRef={cardRef}
                    onClickPrev={() => getPreviousWeekDaysFunc(reservation.employeFormaDays[0].date)}
                    onClickNext={() => getNextWeekDaysFunc(reservation.employeFormaDays[0].date)}
                />
            } */}
        </FadeInSpring>
    )
}

export default memo(ReservationCardItem)