import { kiliyaApi } from '../../../../redux/rtkquery';
import { DETAILS_SALON_ENDPOINT, SALONS_BY_CATEGORIE_ENDPOINT, SALONS_ENDPOINT } from '../../../../routes/api/endpoints';

const salonApi = kiliyaApi.injectEndpoints({
    endpoints: (builder) => ({
        salonsByCategories: builder.query({
            query: (category_slug) => {
                if (!category_slug) throw new Error("category_slug is required in salonsByCategories mutation");
                return {
                    url: SALONS_BY_CATEGORIE_ENDPOINT + category_slug,
                    timeout: 190000
                }
            },
            providesTags: (categorieId) => [categorieId],
        }),
        detailsSalon: builder.query({
            query: (salonSlug) => {
                if (!salonSlug) throw new Error("salonSlug is required in detailsSalon mutation");
                return {
                    url: DETAILS_SALON_ENDPOINT + salonSlug,
                    timeout: 190000
                }
            },
            providesTags: (salonId) => [salonId]
        }),
        salons: builder.query({
            query: () => SALONS_ENDPOINT,
            providesTags: () => ["SALONS"]
        }),
    })
})
export const { useSalonsByCategoriesQuery, useDetailsSalonQuery, useSalonsQuery } = salonApi