export const LOGIN_PAGE_PATH = "/connexion"
export const SIGNUP_PAGE_PATH = "/inscription"
export const HOME_PAGE = "/"
export const CATEGORIE_PAGE = "categorie"
export const SALONS_PAGE = "salons/"
export const SALON_RESERVATION_PAGE = "reservation"

/***************************************************
 *! PROTECTED PATHS
 * *************************************************
 */
export const AUTH_PAGE = "/user/"
export const RESERVATIONS_PAGE = "reservations/"