import React from 'react'
import { errorMsg } from '../../../../helpers/errorMsg'
import { useNavigate } from 'react-router-dom'
import { AUTH_PAGE, SALON_RESERVATION_PAGE } from '../../../../routes/navigation_routes'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentUser } from '../../../../redux/features/userslice'
import LoginForm from '../../../../components/Login/LoginForm'
import { useSigninMutation } from '../../../../features/auth/api/mutations'
import { LoginProvider } from '../../../../features/auth/Provider'

const SignInAndRedirectToReservation = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { currentSalon } = useSelector(state => state.salons)
    const [loginMutation] = useSigninMutation()

    console.log({ currentSalon });


    const onSubmit = (data) => {
        loginMutation({ user: data })
            .unwrap()
            .then(res => {
            dispatch(setCurrentUser(res))
                // dispatch(setFrom(AUTH_PAGE + SALONS_PAGE + currentSalon.id + "/" + SALON_RESERVATION_PAGE))
                return navigate(AUTH_PAGE + currentSalon.slug + "/" + SALON_RESERVATION_PAGE)
            }).catch(err => {
                errorMsg(err.data.message)
        })
    }

    return (
        <LoginProvider>
            <LoginForm onLogin={onSubmit} />
        </LoginProvider>
    )
}

export default SignInAndRedirectToReservation