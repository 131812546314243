import React from "react"
import appLogo from "../assets/images/kiliya.co.png"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { AUTH_PAGE, CATEGORIE_PAGE, SALONS_PAGE } from "../routes/navigation_routes"
import { setCurrentCategory } from "../redux/features/categorySlice"

const Logo = () => {
    const navigate = useNavigate()
    const { currentUser } = useSelector(state => state.user)
    const dispatch = useDispatch()

    return (
        <img
            src={appLogo}
            alt="Kiliya"
            style={{ width: "100px", cursor: "pointer" }}
            onClick={() => {
                if (!currentUser?.access_token) {
                    navigate("/")
                } else {
                    navigate(AUTH_PAGE + CATEGORIE_PAGE + "/spa")
                    dispatch(setCurrentCategory({ id: "", label: "" }))
                }
            }}
        />
    )
}

export default Logo