import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';

import { BiLogoFacebook, BiLogoInstagram } from "react-icons/bi"
// import { BsTwitter } from "react-icons/bs"

import { rootStyles } from '../../style/shared';
import Logo from '../../components/Logo';
import Flex from '../../components/Flex';

import FooterCardItem from '../../components/FooterCardItem';
import SocialMediaLink from '../../components/SocialMediaLink';

const FooterPane = styled.footer`
    max-width:${rootStyles.width.appMaxWidth}px;
    margin: 0 auto;
    padding: ${rootStyles.padding.xl}px ${rootStyles.padding.md}px 90px ${rootStyles.padding.md}px;
`
const FooterListItemPane = styled.li`
    color: #B2B2B2;
    line-height: 25px;
`
const LinkPane = styled(Link)`
    text-decoration: none;
    color: #B2B2B2;
    font-weight: 400;
`

const Footer = () => {
    return (
        <FooterPane>
            <Flex justify="space-between" align="baseline" gap={20} wrap="wrap">
                <div style={{ minWidth: "200px" }}>
                    <Logo />
                </div>
                <FooterCardItem title="Product">
                    <ul>
                        <FooterListItemPane>
                            <LinkPane to="">Qui sommes-nous</LinkPane>
                        </FooterListItemPane>
                        <FooterListItemPane>
                            <LinkPane to="">Recrutement</LinkPane>
                        </FooterListItemPane>
                        <FooterListItemPane>
                            <LinkPane to="">Le blog</LinkPane>
                        </FooterListItemPane>
                        <FooterListItemPane>
                            <LinkPane to="">Mentions légales</LinkPane>
                        </FooterListItemPane>
                    </ul>
                </FooterCardItem>

                <FooterCardItem title="Contact">
                    <ul>
                        <FooterListItemPane>
                            <LinkPane to="">Contactez-nous</LinkPane>
                        </FooterListItemPane>
                        <FooterListItemPane>
                            <LinkPane to="">FAQ</LinkPane>
                        </FooterListItemPane>
                    </ul>
                </FooterCardItem>

                <FooterCardItem title="Follow us">
                    <Flex>
                        <SocialMediaLink>
                            <BiLogoFacebook size={20} />
                        </SocialMediaLink>
                        <SocialMediaLink>
                            <BiLogoInstagram size={20} />
                        </SocialMediaLink>
                    </Flex>
                </FooterCardItem>
            </Flex>
        </FooterPane>
    )
}

export default Footer