import styled from "styled-components";
import { rootStyles } from "../../../../style/shared";
import { Link } from "react-router-dom";

export const LoginPane = styled.div`
    max-width: ${rootStyles.width.appMaxWidth}px;
    margin: auto;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const FormContainerPane = styled.div`
    background-color: ${rootStyles.colors.primary};
    padding: 20px 25px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 10px;
    margin: 0 15px;
`
export const LogoContainerPane = styled.div`
    text-align: center;
    margin-bottom: 30px;
`
export const AuthTitle = styled.h2`
    color: ${rootStyles.colors.action}
`
export const AltLink = styled(Link)`
    color: ${rootStyles.colors.action};
    text-decoration: underline;
    &:hover{
        color: #000
    }
`