export const welcomeSignup = {
    duration: 4000,
    position: 'top-center',

    // Styling
    style: {},
    className: '',

    // Custom Icon
    icon: '🖐️',

    // Change colors of success/error/loading icon
    iconTheme: {
        primary: '#fff',
        secondary: '#000',
    },

    // Aria
    ariaProps: {
        role: 'status',
        'aria-live': 'polite',
    },
}