import React from "react";
import styled from "styled-components";
import { AiOutlineClockCircle } from "react-icons/ai";

import { rootStyles } from "../../../../style/shared";
import Flex from "../../../../components/Flex";
import Button from "../../../../components/Button";
import Div from "../../../../components/Div";
import Dot from "../../../../components/Dot";

const ContainerPane = styled.div`
  border-bottom: 1px solid ${rootStyles.colors.salonServiceBorder};
  padding: 12px 0;
`;
const FlexPane = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PriceChoicePane = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: ${({ wrap }) => wrap};
  min-width: ${({ minwidth }) => `${minwidth}px`};
  gap: ${({ gap }) => `${gap}px`};
  @media screen and (max-width: 768px) {
    justify-content: space-between;
  }
`;

const ServiceTitlePane = styled.p`
  font-size: ${rootStyles.fontSize.sm}px;
`;
export const ServiceTimePane = styled.h5`
  font-size: ${rootStyles.fontSize.sm}px;
  color: ${rootStyles.colors.avisColor};
  margin-bottom: 0;
  margin: 0;
  padding: 0;
`;
const PriceSmallPane = styled.span`
  color: ${rootStyles.colors.avisColor};
  font-size: ${rootStyles.fontSize.sm}px;
`;
const PriceNormalPane = styled.span`
  color: ${rootStyles.colors.action};
  font-size: ${rootStyles.fontSize.sm}px;
`;
const NewPriceNormalPane = styled.span`
  color: ${rootStyles.colors.action};
  font-size: ${rootStyles.fontSize.sm}px;
  padding-bottom: 10px;
  font-weight: 700;y
`;
const SpanPane = styled.span`
  font-size: ${rootStyles.fontSize.sm}px;
  font-weight: 300;
`;

const SalonServiceCardItem = ({
  name,
  description = "",
  duration,
  price,
  has_promotion,
  onClickBtn,
}) => {
  return (
    <ContainerPane>
      <Flex justify={"space-between"} align={"center"} wrap={"wrap"} gap={8}>
        <Div>
          <ServiceTitlePane>{name}</ServiceTitlePane>
          <SpanPane>{description}</SpanPane>
        </Div>
        <Flex
          align={"center"}
          gap={10}
          justify={"space-between"}
          minwidth={300}
          wrap={"wrap"}
        >
          <ServiceTimePane>
            <Flex align={"center"} gap={5}>
              <AiOutlineClockCircle /> {duration} min
            </Flex>
          </ServiceTimePane>
          <Dot />
          {has_promotion && <PriceSmallPane>20 FCFA</PriceSmallPane>}
          <PriceNormalPane>{price} FCFA</PriceNormalPane>
          <Button
            responsive
            onClick={onClickBtn}
            letterSpacing={2}
            fontSize={rootStyles.fontSize.sm}
            fontWeight={500}
            textTransform="normal"
            borderWidth={2}
            paddingHorizontal={8}
            borderStyle="solid"
            borderColor={rootStyles.colors.action}
            borderRadius={rootStyles.borderRadius.sm}
            backgroundColor={
              !true ? rootStyles.colors.action : rootStyles.colors.primary
            }
            color={!true ? rootStyles.colors.primary : rootStyles.colors.action}
            height={20}
          >
            Choisir
          </Button>
        </Flex>
      </Flex>
    </ContainerPane>
  );
};

export const SalonServiceNewCardItem = ({
  name,
  description = "",
  duration,
  price,
  has_promotion,
  onClickBtn,
}) => {
  return (
    <ContainerPane>
      <FlexPane>
        <Div>
          <ServiceTitlePane>{name}</ServiceTitlePane>
          <SpanPane>{description}</SpanPane>
        </Div>
        <PriceChoicePane gap={10} minwidth={300} wrap={"wrap"}>
          {has_promotion && <PriceSmallPane>20 FCFA</PriceSmallPane>}
          <Flex justify={"space-between"} minwidth={100} gap={15} wrap={"wrap"}>
            <div>
              <NewPriceNormalPane>{price} FCFA</NewPriceNormalPane>
              <ServiceTimePane>
                <Flex align={"center"} gap={8}>
                  <AiOutlineClockCircle /> {duration} min
                </Flex>
              </ServiceTimePane>
              {/* <Dot /> */}
            </div>
            <Button
              responsive
              onClick={onClickBtn}
              letterSpacing={2}
              fontSize={rootStyles.fontSize.sm}
              fontWeight={500}
              color="#fff"
              backgroundColor={rootStyles.colors.action}
              textTransform="normal"
              // borderWidth={2}
              paddingHorizontal={8}
              borderStyle="solid"
              borderColor={rootStyles.colors.action}
              borderRadius={rootStyles.borderRadius.md}
              // backgroundColor={
              //   !true ? rootStyles.colors.action : rootStyles.colors.primary
              // }
              // color={!true ? rootStyles.colors.primary : rootStyles.colors.action}
              height={20}
            >
              Choisir
            </Button>
          </Flex>
        </PriceChoicePane>
      </FlexPane>
    </ContainerPane>
  );
};

export default SalonServiceCardItem;
