import { createContext, memo, useMemo, useState } from "react";

export const SidebarDrawerContext = createContext()

const SidebarDrawerProvider = ({ children }) => {
    const [visible, setVisible] = useState(false)



    const values = useMemo(() => ({
        visible,
        openSidebarDrawer: () => setVisible(true),
        closeSidebarDrawer: () => setVisible(false),
    }), [visible])

    console.log("SidebarDrawerProvider", values);

    return <SidebarDrawerContext.Provider value={values}>
        {children}
    </SidebarDrawerContext.Provider>
}

export default memo(SidebarDrawerProvider)