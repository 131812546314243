import React, { useEffect } from 'react'
import FadeTransition from '../../../components/FadeInTransition'
import MainContent from '../../../components/MainContent'
import Div from '../../../components/Div'
import styled from 'styled-components'
import { rootStyles } from '../../../style/shared'
import Flex from '../../../components/Flex'
import ReservationHistoryCardItem from './components/ReservationHistoryCardItem'
import { useReservationHistoriqueQuery } from '../api/queries'
import PlaceholderLoader from '../../../components/PlaceHolderLoader'
import { useSelector } from 'react-redux'
import GoBack from '../../../components/GoBack'

const LeftPane = styled.div`
    flex: 1;
    background-color: ${rootStyles.colors.primary};
    height: 300px;
    box-shadow: ${rootStyles.shadow.card};
    padding: ${rootStyles.padding.lg}px;
    border-radius: ${rootStyles.borderRadius.md}px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`
const RightPane = styled.div`
    flex: 2;
    background-color: ${rootStyles.colors.primary};
    padding: ${rootStyles.padding.lg}px;
    box-shadow: ${rootStyles.shadow.card};
    border-radius: ${rootStyles.borderRadius.md}px
`
const TitlePane = styled.h6`
    font-size: ${rootStyles.fontSize.md}px;
    font-weight: 600;
    margin-bottom: 20px;
`

const ReservationsHistoryPage = () => {
    const { currentUser } = useSelector(state => state.user)
    const { data: historiqueReservation = [], isLoading, isError, refetch } = useReservationHistoriqueQuery(currentUser.user.id)
    // console.log({ historiqueReservation, currentUser });
    useEffect(() => {
        refetch()
    }, [])
    return (
        <FadeTransition in={true}>
            <MainContent>
                <Div mt={10} mb={20}>
                    <GoBack label={"Retour"} />
                </Div>
                <Div className="container-fluid p-0">
                    <Flex gap={20}>
                        <LeftPane>
                            <TitlePane>Mon compte</TitlePane>
                        </LeftPane>
                        <RightPane>
                            <TitlePane>Mes rendez-vous</TitlePane>
                            {
                                isError ? <p>Une erreur est survenue</p> :
                                    isLoading ? [1, 2, 3, 4].map((item) => <PlaceholderLoader key={item} />) :
                                        historiqueReservation.length ?
                                            historiqueReservation.map(reservation => {
                                                return <ReservationHistoryCardItem reservation={reservation} key={reservation.id} />
                                            }) :
                                            <p>Pas encore de reservations</p>
                            }
                        </RightPane>
                    </Flex>
                </Div>
            </MainContent>
        </FadeTransition>
    )
}

export default ReservationsHistoryPage