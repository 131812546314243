/**
 * 
 * @param {number} duration 
 * @return string
 */
export const convertTimeToHour = (duration) => {
    const durationMunitues = Number(duration)
    const durationTimeHour = Math.floor(durationMunitues / 60)
    const durationTimeMinutes = Math.floor(durationMunitues % 60)

    return durationTimeHour === 0 ? `${durationTimeMinutes}` : `${durationTimeHour}h ${durationTimeMinutes}`
}