import Accordion from "react-bootstrap/Accordion";
import styled from "styled-components";
import { rootStyles } from "../style/shared";
import { useState } from "react";
const StyledAccordion = styled(Accordion)`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
`;

const StyledAccordionItem = styled(StyledAccordion.Item)`
  border: none;
  margin: 0;
  padding: 0;
`;

const StyledAccordionHeader = styled(StyledAccordion.Header)`
  margin: 0;
  padding: 0;
`;

const HeaderTitlePane = styled.h6`
  font-size: 16px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  color: #484848;

  @media screen and (max-width: 600px) {
    font-size: ${rootStyles.fontSize.sm}px;
  }
`;

const Badge = styled.span`
  background-color: #fef2ff;
  color: #65376a;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 3px 9px;
  border-radius: 12px;
  margin-right: 18px;
`;

const Container = styled.div`
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 600px;
  margin: 0 auto;
`;

const Content = styled.div`
  overflow: hidden;
  max-height: ${({ showMore }) => (showMore ? "1000px" : "80px")};
  transition: max-height 0.5s ease;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 400;
  color: #484848;
  line-height: 25px;
`;

const ShowMoreButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  color: ${rootStyles.colors.action};
  cursor: pointer;
  font-size: 1.1rem;
  text-decoration: underline;
  text-transform: capitalize;
  padding: 0;
  font-weight: 500;
`;

const AccordionItem = ({ eventKey, accordionTitle, prestations, children }) => {
  return (
    <StyledAccordionItem eventKey={eventKey}>
      <StyledAccordionHeader>
        <HeaderTitlePane>
          {accordionTitle}

          <Badge>
            {prestations === undefined
              ? ""
              : prestations > 1
              ? `${prestations} prestations`
              : `${prestations} prestation`}
          </Badge>
        </HeaderTitlePane>
      </StyledAccordionHeader>
      <Accordion.Body>{children}</Accordion.Body>
    </StyledAccordionItem>
  );
};

export default AccordionItem;

export const Description = ({ data }) => {
  const [showMore, setShowMore] = useState(false);

  const MAX_LENGTH = 300;

  const stripHTML = (html) => {
    const withSpaces = html.replace(/<h3>/g, "\n\n<h3>");
    const doc = new DOMParser().parseFromString(withSpaces, "text/html");
    return doc.body.textContent || "";
  };

  const rawDescription = data.description || "";
  const description = stripHTML(rawDescription);
  const isLongDescription = description.length > MAX_LENGTH;

  return (
    <Container>
      <Content showMore={showMore}>
        <Paragraph>
          {showMore || !isLongDescription
            ? description
            : `${description.substring(0, MAX_LENGTH)}... `}
        </Paragraph>
      </Content>
      {isLongDescription && (
        <ShowMoreButton onClick={() => setShowMore(!showMore)}>
          {showMore ? "Afficher Moins" : "Afficher Plus"}
        </ShowMoreButton>
      )}
    </Container>
  );
};
