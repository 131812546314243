import React from 'react'
import styled from 'styled-components'

const FlexPane = styled.div`
    flex: ${({ flex }) => flex};
    display: flex;
    flex-direction: ${({ direction }) => direction};
    justify-content: ${({ jc }) => jc};
    align-items: ${({ al }) => al};
    align-self: ${({ alignSelf }) => alignSelf};
    gap: ${({ gap }) => `${gap}px`};
    flex-wrap: ${({ wrap }) => wrap};
    min-width: ${({ minwidth }) => `${minwidth}px`};
    height: ${({ height }) => height ? `${height}%` : undefined};
`

const Flex = ({ direction = "row",
    justify,
    align,
    alignSelf,
    gap = 5,
    children, wrap,
    flex,
    minwidth,
    height
}
) => {
    return (
        <FlexPane
            height={height}
            wrap={wrap}
            direction={direction}
            jc={justify}
            al={align}
            alignSelf={alignSelf}
            gap={gap}
            flex={flex}
            minwidth={minwidth}
        >
            {children}
        </FlexPane>
    )
}

export default Flex