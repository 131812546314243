import { Outlet, createBrowserRouter } from "react-router-dom";
import { lazy } from "react";

import {
  AUTH_PAGE,
  CATEGORIE_PAGE,
  HOME_PAGE,
  LOGIN_PAGE_PATH,
  RESERVATIONS_PAGE,
  SALONS_PAGE,
  SALON_RESERVATION_PAGE,
  SIGNUP_PAGE_PATH,
} from "./navigation_routes";

import AppShell from "../layout/AppShell";
// import HomePage from "../pages/home/HomePage";
// import SalonPage from "../pages/salons/ui/SalonPage";
// import SalonDetailsPage from "../pages/salons/ui/SalonDetailsPage";
import ErrorBoundary from "../components/ErrorBoundary";
import Page404 from "../components/Page404";
import SalonReservationPage from "../pages/salons/ui/SalonReservationPage";
import AuthAppSell from "../layout/AuthAppSell";
import SalonReservationUserConnected from "../pages/salons/ui/SalonReservationUserConnected";
import ReservationsHistoryPage from "../pages/reservations/ui/ReservationsHistoryPage";
import LoginPage from "../pages/auth/ui/LoginPage";
import SignupPage from "../pages/auth/ui/SignupPage";

const HomePage = lazy(() => import("../pages/home/HomePage"));
const SalonPage = lazy(() => import("../pages/salons/ui/SalonPage"));
// const SalonDetailsPage = lazy(() => import("../pages/salons/ui/SalonDetailsPage"))
const NewSalonDetailsPage = lazy(() =>
  import("../pages/salons/ui/NewSalonDetailsPage")
);

export const publicRoutes = [
  {
    path: HOME_PAGE,
    element: <AppShell />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <HomePage />,
        // element: <>
        //     <Navbar />
        //     <Outlet />
        // </>,
        // children: [
        //     {
        //         path: CATEGORIE_PAGE + "/:slug",
        //         element: <SalonPage />,
        //     },
        //     {
        //         path: ":slug",
        //         element: <Outlet />,
        //         children: [
        //             {
        //                 path: "",
        //                 element: <SalonDetailsPage />
        //             },
        //             {
        //                 path: SALON_RESERVATION_PAGE,
        //                 element: <SalonReservationPage />
        //             }
        //         ]
        //     }
        // ]
      },
      {
        path: CATEGORIE_PAGE + "/:slug",
        element: <SalonPage />,
      },
      {
        path: ":slug",
        element: <Outlet />,
        children: [
          // {
          //     path: "",
          //     element: <SalonDetailsPage />
          // },
          {
            path: "",
            element: <NewSalonDetailsPage />,
          },
          {
            path: SALON_RESERVATION_PAGE,
            element: <SalonReservationPage />,
          },
        ],
      },
      {
        path: LOGIN_PAGE_PATH,
        element: <LoginPage />,
      },
      {
        path: SIGNUP_PAGE_PATH,
        element: <SignupPage />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export const privateRoutes = [
  {
    path: AUTH_PAGE,
    element: <AuthAppSell />,
    children: [
      {
        path: CATEGORIE_PAGE + "/:slug",
        element: <SalonPage />,
      },
      {
        path: ":slug",
        element: <Outlet />,
        children: [
          //   {
          //     path: "",
          //     element: <SalonDetailsPage />,
          //   },
          {
            path: "",
            element: <NewSalonDetailsPage />,
          },
          {
            path: SALON_RESERVATION_PAGE,
            element: <SalonReservationUserConnected />,
          },
        ],
      },
      {
        path: RESERVATIONS_PAGE,
        element: <ReservationsHistoryPage />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];
