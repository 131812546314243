import { useForm, FormProvider } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";

const loginSchema = yup.object().shape({
    login: yup.string().required("Email obligatoire"),
    password: yup.string().min(5, "Le mot de passe doit avoir au moins 5 caractères").required("Mot de passe obligatoire")
})

export const LoginProvider = ({ children }) => {
    const methods = useForm({
        values: initialValues,
        resolver: yupResolver(loginSchema)
    })

    return <FormProvider {...methods}>{children}</FormProvider>
}

const initialValues = {
    login: "",
    password: ""
}